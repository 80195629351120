body {
	font-family: ArialBlackRegular, "Arial Black", serif;
	font-size: 15px;
}


#toppage {
	font-family: Trebuchet MS, "Trebuchet MS", serif;
	margin-bottom: 20px;
	#logo {
		margin: 20px 0 40px;
	}
	.top-nav {
		display: inline-block;
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;

		li {
			display: inline-block;
			float: left;
			border-right: 1px solid #007FB3;
			line-height: 15px;
			padding: 0 3px;

			@include breakpoint(small only) {
				margin-bottom: 10px;
			}

			&:first-child {
				/*padding-left: 3px;*/
			}

			&:last-child {
				border-right: 0px;
				padding-right: 0px;
			}

			a {
				font-family: ArialBlackRegular, "Arial Black", serif;
				font-size: 11.8px;
				line-height: 15px;
				letter-spacing: 0;
				color: #007fb3;

				&:hover {
					color: #7FA86C ;
				}
			}
		}
	}
}

#page-grid {
	font-family: Trebuchet MS, "Trebuchet MS", serif;
	font-size: 15px;
	line-height: 22px;
	color: #010101;

	main {
		margin-top: 0;
	}

	img {
		margin-bottom: 15px;
	}

	strong, b {
		font-family: ArialBlackRegular, "Arial Black", serif;
		font-weight: normal;
		color: #7FA86C;
		letter-spacing: 0;
	}

	.page-content {
		margin: 0 130px;

		@include breakpoint(small only) {
			margin: 0 10px;
		}

		strong, b {
			font-family: ArialBlackRegular, "Arial Black", serif;
			font-weight: normal;
			color: #7FA86C;
			letter-spacing: 0;
		}
	}

	.news-events {
		h2 {
			font-family: Trebuchet MS, "Trebuchet MS", serif;
			color: #007FB3;
			font-size: 23px;
			font-weight: normal;
			margin: 0 0 5px;
			padding: 0;
			line-height: 30px;
		}

		.news-item {
			margin-bottom: 40px;
			.date {
				font-family: ArialBlackRegular,'Arial Black',serif; 
				color: #7fa86c;
			}
		}

	}
	.past-programs-left {
		@include breakpoint(small only) {
			text-align: center;
		}
	}
	.past-programs {
		.program-item {
			margin-bottom: 20px;

			a {
				font-family: ArialBlackRegular,'Arial Black',serif;
				color: #7fa86c;
			}

			.date {
				font-family: Trebuchet MS, "Trebuchet MS", serif;
			}
		}
	}

	.top-resources {
		padding: 0 34px;

		@include breakpoint(small only) {
			padding: 0px 0.625rem;
		}
		ul {
			padding: 0 20px;
			li {
				margin-bottom: 10px;
			}
		}
	}

	.resource-item {
		h2 {
			font-family: Trebuchet MS, "Trebuchet MS", serif;
			color: #007FB3;
			font-size: 23px;
			font-weight: normal;
			margin: 0px;
			padding: 0;
			line-height: 30px;

			a {
				color: #007FB3;

				&:hover {
					color: #7fa86c;
				}
			}
		}

		.date {
				font-family: ArialBlackRegular,'Arial Black',serif; 
				color: #7fa86c;
				margin-bottom: 1px;
		}
	}


	@include breakpoint(small only) {
		.order-1 {
			order: 1;
		}

		.order-2 {
			order: 2;
		}

		img {
			width: 100%;
		}
	}
}


#footer {
	font-family: Trebuchet MS, "Trebuchet MS", serif;
	padding-bottom: 30px;
	@include breakpoint(small only) {
		margin-top: 10px;
	}
	margin-top: 60px;
	.footer-nav {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;
		@include breakpoint(small only) {
			width: 100%;
		}
		li {
			display: inline-block;
			float: left;
			font-family: Trebuchet MS, "Trebuchet MS", serif;
			font-size: 15px;
			border-right: 1px solid #007FB3;
			line-height: 15px;
			padding: 0 5px;
			border-bottom: 0px solid #007FB3;

			&:first-child {
				padding-left: 3px;
			}

			&:last-child {
				border-right: 0px;
				padding-right: 0px;
			}
		}
		@include breakpoint(small only) {

			li {
				display: block;
				float: none;
				font-size: 17px;
				line-height: 23px;
				padding: 7px 0 8px;
				border-right: 0px;
				border-bottom: 1px solid #007FB3;

				&:last-child {
					border-bottom: 0px solid #007FB3;
				}

				a {
					display: inline-block;
					width: 100%;
				}
			}
		}


	}

	.copyright {
		@include breakpoint(small only) {
			width: 100%;
			text-align: center;
		}
		color: #007fb3;
		font-size: 12px;
		line-height: 18px;
		margin: 40px 0 0;
		padding: 0;
		text-align: left;

	}
}


#pdfpopup  {

	.b-close {
	    position: absolute;
	    right: -15px;
	    top: -15px;
	}

	.content {
		background: #fff;

		strong, b {

		    font-family: ArialBlackRegular, "Arial Black", serif;
		    color: #7FA86C;
		    font-weight: normal;
		    letter-spacing: 0;
		}

	}
}

/* default style */
.selectnav { display: none; }

@include breakpoint(small only) {
	.js #top-nav {
		display: none;
	}

	.js .selectnav { display: block; }

}