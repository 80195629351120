#home-grid {
	margin: 20px auto 0;

	@include breakpoint(small only) {
		margin: 0px;
		padding: 0px;
	}

	@include breakpoint(small only) {

	    img {
	    	width: 100%;
	    }
	}

	.cell {
		@include breakpoint(small only) {
	      text-align: center;
	    }
	}


}

#home-topnav {
	background: url('../images/NHS-GreenPanel.jpg') no-repeat top left;

	@include breakpoint(small only) {
	      background: url('../images/NHS-GreenPanel.jpg') no-repeat top center;
	      background-size: cover;
	}

	ul {
		list-style: none;
		text-align: center;
		margin: 0;
		padding-top: 33px;

		@include breakpoint(small only) {
			padding-top: 43px;
			padding-bottom: 43px;
		}

		li {
			line-height: 26px;

			@include breakpoint(small only) {
				line-height: 34px;
			}

			a {
				color: #fff;

				&:hover {
					color: #C8DDBE;
				}
			}
		}
	}
}

#home-about {
	background: url('../images/NHS-GoldPanel.jpg') no-repeat top left;

	@include breakpoint(medium) {
		background: url('../images/NHS-GoldPanel.jpg') no-repeat top center;
		padding: 0 0px 30px;
	}

	@include breakpoint(small only) {
		background: url('../images/NHS-GoldPanel.jpg') no-repeat top center;
		background-size: cover;
		padding: 10px 50px 43px;
	}

	p {
		font-family: ArialBlackRegular, "Arial Black", serif;
		font-size: 15px;
		line-height: 22px;
		margin-top: 50px;
		padding: 0 12px;
		/* position: absolute; */
		text-align: center;
		/* width: 226px; */
		color: #689354;

		a {
			color: #7FA86C;
			&:hover {
				color: #fff;
			}
		}
	}
}